import React from 'react';

import Layout from '../components/Layout';
import SteeringForm from '../components/SteeringForm';

import duffFull from '../assets/images/duff-phelps--full.png';
import designIcon from '../assets/images/design.svg';
import changeIcon from '../assets/images/change-management.svg';
import trainingIcon from '../assets/images/training.svg';
import quote from '../assets/images/mdi-quote.svg';
import implementationPartners from '../assets/images/implementation-partners.jpg';
import implementationPartnersMobile from '../assets/images/implementation-partners-mobile.png';

import raphaelCardona from '../assets/images/raphael-cardona.jpg';
import nadineStuttle from '../assets/images/nadine-stuttle.jpg';
import fibonacci from '../assets/images/fibonacci-full.svg';

const ImplementationPartners = ({ path }) => (
  <Layout
    pageName="implementation"
    path={path}
    title="Implementation Partners - Fibonacci"
    image="/metaimages/implementation-group.jpg"
    description="Legal transformation made easy. Because people, process and change transformation matters."
  >
    <div className="banner">
      <div className="chip">IMPLEMENTATION PARTNERS</div>
      <h1>Legal transformation</h1>
      <h1>made easy</h1>
      <p>Because people, process and change transformation matters</p>
    </div>
    <div className="implementation-container">
      <div className="content">
        <div className="image-container">
          <img
            src={implementationPartners}
            className="image"
            alt="implementation partners"
          />
          <img
            src={implementationPartnersMobile}
            className="mobile"
            alt="implementation partners"
          />
        </div>
        <p>
          Fibonacci can be set up in a matter of seconds for smaller
          organisations. However, for those larger organisations who want to
          incorporate their well developed know-how and other technology
          products into Fibonacci, we offer a full range of professional
          services - with support from the industry’s leading legal technology
          implementation specialists - to help transition your workflow into
          Fibonacci.
        </p>
        <div className="item">
          <div className="bullet">
            <img className="icon" src={designIcon} alt="design" />
          </div>
          <div className="text">
            <p className="title">Discovery & Design</p>
            <p className="description">
              In order to break the rules, we must understand these rules
              intimately. We will work with your organisation to truly
              understand your processes and know-how and work with your key
              stakeholders in identifying all possible areas of improvement.
            </p>
          </div>
        </div>
        <div className="item">
          <div className="bullet">
            <img className="icon" src={changeIcon} alt="change" />
          </div>
          <div className="text">
            <p className="title">Change-management and roll-out</p>
            <p className="description">
              Technology is not a panacea and there must be a structured
              approach in the roll-out of new solutions in order to effect
              change and develop new and best practices. We will guide you every
              step of the way to ensure your implementation of Fibonacci can
              meet your objectives.
            </p>
          </div>
        </div>
        <div className="item">
          <div className="bullet">
            <img className="icon" src={trainingIcon} alt="training" />
          </div>
          <div className="text">
            <p className="title">
              Fibonacci&apos;s Legal Project Management Training
            </p>
            <p className="description">
              Staying ahead of the competition is a critical component to
              staying relevant. We’re focused on improving the quality of legal
              project management in the industry and can provide access to
              industry leading thought leaders to deliver valuable training
              solutions for you and your staff.
            </p>
          </div>
        </div>
        <p>
          <i>
            Enquire about these services to get started by messaging Raphael
            below.
            <br />
            Or if you&apos;re interested in becoming an implementation partner,
            please fill out the form.
          </i>
        </p>
        <div className="base-chip -large author">
          <img className="avatar -no-border" src={raphaelCardona} alt="" />
          <div className="text">
            <div className="title">
              <span className="name">Raphael Cardona</span>
            </div>
            <div className="subtitle">Head of Product, Fibonacci</div>
            <img className="logo -custom" src={fibonacci} alt="" />
          </div>
        </div>
        <a
          className="base-button -inline -message message-link"
          href="mailto:raph@fibonacci.legal"
        >
          Message Raphael
        </a>
      </div>
      <SteeringForm initialPartnership="Implementation Partners" />
    </div>
    <div className="testimonial-container">
      <img className="icon" src={quote} alt="" />
      <div className="text">
        Fibonacci allows us to transform leading legal departments worldwide at
        pace. With Fibonacci&apos;s flexibility and open integration philosophy,
        we&apos;re better able to help our clients design and roll-out systems
        that can truly expose new productivity and efficiency improvements
        across their workflow.
      </div>
      <div />
      <div className="base-chip -large">
        <img src={nadineStuttle} className="avatar" alt="Nadine Stuttle" />
        <div className="text">
          <div className="title">
            <span className="name">Nadine Stuttle</span>
            <span className="position">Managing Partner, Duff and Phelps</span>
          </div>
          <img className="logo" src={duffFull} alt="" />
        </div>
      </div>
    </div>
  </Layout>
);

export default ImplementationPartners;
